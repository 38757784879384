import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import QueryString from 'query-string';
import * as Yup from 'yup';
import 'react-phone-input-2/lib/style.css';
import Cryptr from 'cryptr';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';
import {
  login, isLoggedInUserAdmin, registerUser, isUserAlreadyRegistered,
  setCsrfToken, fetchCsrfToken, setUserEmail, isFirstTimeLogin,
} from '../actions/login';
import constant from '../actions/constant';
import projectConstant from '../../project/constant/constant';
import RenderIf from '../../../ui-components/Common';
import authService from '../../../redux/services/authService';
import accountService from '../../../redux/services/accountService';
import BrandWrapper from '../../BrandedWrapper/component/brandedwrapper';
import {
  validateOnDemandUserByEmail,
  checkIfUserRestrictedToLogin,
} from '../actions/iam';
import { checkDeepLinkUser } from '../../project/actions/project';
import { SpinnerButton, Spinner } from '../../../ui-components/Loader';
import './Login.scss';
import ErrorAlertModel from '../../../ui-components/ErrorAlertModel/ErrorAlertModel.jsx';
import projectService from '../../../redux/services/projectService';

const cryptr = new Cryptr(__CONFIG__.encryptSecret);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSignIn: false,
      hasError: false,
      errorMessage: '',
      email: '',
      isLogin: true,
      isRegister: false,
      registrationToken: '',
      registrationEmail: '',
      isLoginFromRedirectUrl: false,
      showInvalidUserAlert: false,
      showErrorAlert: false,
    };
    this.validateUser = this.validateUser.bind(this);
    this.redirectToIAM = this.redirectToIAM.bind(this);
  }

  // Validation Schema for User Registeration
  validationSchema() {
    return Yup.object({
      firstName: Yup.string().trim().required('First Name is required'),
      lastName: Yup.string().trim().required('Last Name is required'),
      mobileNumber: Yup.string().trim().matches(/^[0-9]{0,20}$/, 'Mobile Number should not exceed more than 20 digits'),
      password: Yup.string().required('Password is required')
        .matches(/(?=.*\d)/, 'Password must contain 1 digit')
        .matches(/(?=.*[A-Z])/, 'Password must contain 1 upper case letter')
        .matches(/(?=.*[a-z])/, 'Password must contain 1 lower case letter')
        .matches(/(?=.*\W)/, 'Password must contain 1 special character')
        .min(8, 'Password length should not be less than 8 characters')
        .max(19, 'Password length should not exceed 19 characters'),
      confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Password does not match'),
    });
  }

  componentWillMount() {
    this.checkIfAlreadyLoggedIn();
  }

  checkIfAlreadyLoggedIn = async () => {
    if (authService.isAuthenticated(true)) {
      if (this.props.guid && this.props.guid.length) {
        const deeplinkResponse = await projectService.checkDeepLinkUser(this.props.guid);
        if (deeplinkResponse && deeplinkResponse.data && !deeplinkResponse.data.isUserStakeholder) {
          this.setState({ showErrorAlert: true });
        }
      } else if (sessionStorage.getItem(projectConstant.selectedProjectId)) {
        this.props.history.push(constant.personalDashboard);
      } else {
        this.props.history.push(constant.homePage);
      }
    }
  }

  onCloseErrorAlertHandler = () => {
    this.setState({ showErrorAlert: false });
  }

  componentDidMount() {
    const {
      authMessage, userRestrictedPage,
    } = constant;
    const { location } = this.props;
    const queryStringData = QueryString.parse(location.search);
    const {
      code, invalidUser, registrationToken, email,
    } = queryStringData;
    const redirectUserEmail = authService.getRedirectUserEmail();
    if (redirectUserEmail) {
      const userInfo = {};
      userInfo.email = cryptr.decrypt(redirectUserEmail);
      if (!this.validateRedirectUserEmail(userInfo.email)) {
        this.setState({ isLoginFromRedirectUrl: false });
        authService.removeRedirectUserEmail();
      } else {
        this.setState({ email: userInfo.email, isLoginFromRedirectUrl: true });
        setTimeout(() => {
          this.validateUser(userInfo);
        }, 1000);
      }
    }
    this.showSignInLoader(true);
    this.props.fetchCsrfToken().then(async (res) => {
      const { csrfToken } = res.data;
      if (csrfToken) {
        await this.props.setCsrfToken(csrfToken);
        setTimeout(() => {
          if (email && registrationToken) {
            this.props.isUserAlreadyRegistered({ email }).then((response) => {
              this.showSignInLoader(false);
              this.props.checkIfUserRestrictedToLogin({ email }).then((userResponse) => {
                if (userResponse.data.isUserAbleToLogin) {
                  if (response.data === 'no') {
                    this.setState({
                      isLogin: false,
                      isRegister: true,
                      registrationToken,
                      registrationEmail: email,
                    });
                  } else {
                    this.setState({
                      isLogin: true,
                      isRegister: false,
                    });
                  }
                } else {
                  this.navigate(userRestrictedPage);
                }
              });
            });
          } else if (code) {
            this.props.login({ code, requestFor: constant.requestFor }).then((response) => {
              this.onAuthResponse(response, email);
              this.setState({ isLoginFromRedirectUrl: false });
            });
          } else if (invalidUser !== undefined) {
            if (invalidUser === 'false') {
              this.showSignInLoader(false);
              this.showErrorMessage(authMessage.PERSPECTIVE_AUTH_FAIL);
            }
            if (invalidUser === 'true') {
              this.showSignInLoader(false);
              this.setState({ showErrorAlert: true });
            }
          } else {
            this.showSignInLoader(false);
          }
        }, 100);
      }
    });
  }

  logoutUser() {
    if (authService.isAuthenticated()) {
      const { email, user_id: userId } = authService.getUserInfo();
      authService.setSignoutUserEmail(email);
      accountService
        .logUserSignOut(userId, constant.logSignOutMessages.inactivityThresholdMessage)
        .then(() => {
          authService.clearAllStorage();
          const uriParams = QueryString.stringify({ invalidUser: true });
          const redirectUri = `${this.props.redirectUrl}/?${uriParams}`;
          // eslint-disable-next-line no-undef
          window.location = `${__CONFIG__.iamSignoutUri}${redirectUri}`;
        });
    }
  }

  onAuthResponse(response) {
    const { authResponse } = response.data;
    const firstTimelogin = authResponse.data.firsttimelogin;
    const {
      authStatus, authMessage, homePage, personalDashboard,
    } = constant;
    this.showErrorMessage('', false);
    if (authResponse.status === authStatus.OK) {
      localStorage.setItem('isMaxSessionReached', false);
      const {
        // userInfo,
        isUserStakeholder,
      } = authResponse.data;
      if (isUserStakeholder) {
        authService.removeSignoutUserEmail();
        this.props.isLoggedInUserAdmin(authResponse.data.userInfo.isSysAdmin);
        if (sessionStorage.getItem(projectConstant.selectedProjectId)) {
          this.props.history.push(personalDashboard);
          this.props.isFirstTimeLogin(firstTimelogin);
        } else {
          this.props.history.push(homePage);
          this.props.isFirstTimeLogin(firstTimelogin);
        }
      } else {
        this.logoutUser();
      }
      return;
    }
    if (authResponse.status === authStatus.IAM_AUTH_ERROR) {
      this.showErrorMessage(authMessage.IAM_AUTH_FAIL);
      return;
    }
    if (authResponse.status === authStatus.USER_REGISTRATION_ERROR) {
      this.showErrorMessage(authMessage.USER_REGISTRATION_FAIL);
      return;
    }
    if (authResponse.status === authStatus.PERSPECTIVE_AUTH_ERROR) {
      const uriParams = QueryString.stringify({ invalidUser: false });
      const redirectUri = `${this.props.redirectUrl}/?${uriParams}`;
      this.redirectToUrl(`${__CONFIG__.iamSignoutUri}${redirectUri}`);
    }
    if (!authResponse.data.isUserStakeholder) {
      this.logoutUser();
    }
    if (authResponse.status === authStatus.PERSPECTIVE_AUTH_ERROR
      && !authResponse.data.isUserStakeholder) {
      accountService.logUserSignOut(
        this.state.userId,
        constant.logSignOutMessages.manualSignOutMessage,
      ).then(() => {
        document.cookie = 'ppid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        authService.clearAllStorage();
        // eslint-disable-next-line no-undef
        window.location = `${__CONFIG__.iamSignoutUri}${this.props.redirectUrl}`;
        const uriParams = QueryString.stringify({ invalidUser: false });
        const redirectUri = `${this.props.redirectUrl}/?${uriParams}`;
        this.redirectToUrl(`${__CONFIG__.iamSignoutUri}${redirectUri}`);
      });
    }
  }

  showErrorMessage(errorMessage, showError) {
    const hasError = showError || true;
    this.setState({ hasError, errorMessage });
  }

  showSignInLoader(showLoader) {
    this.setState({ isSignIn: showLoader });
  }

  validateUser(userInfo) {
    const { accountActivationPage, userRestrictedPage } = constant;
    if (!this.validateEmail(userInfo.email)) {
      return;
    }
    this.setState({ isLoading: true, hasError: false, errorMessage: '' });
    this.props.checkIfUserRestrictedToLogin({ email: userInfo.email }).then((response) => {
      const { data } = response;
      if (data.isUserAbleToLogin) {
        this.props.validateOnDemandUserByEmail({
          email: userInfo.email,
          isWebQuestionnaire: true,
        }).then((res) => {
          this.props.setUserEmail(userInfo.email);
          if (!res.data.invitationSent) {
            this.redirectToIAM(userInfo.email);
          } else {
            this.navigate(accountActivationPage);
          }
          authService.removeRedirectUserEmail();
        });
      } else {
        this.navigate(userRestrictedPage);
      }
    });
  }

  navigate(path) {
    const { location } = this.props;
    if (location.pathname !== path) this.props.history.push(path);
  }

  isInternalPwcUser(emailId) {
    const RU = /^[-+.0-9A-Z_a-z]+@([A-Za-z]+(.)?)*pwc.com$/;
    const RE = /^[-+.0-9A-Z_a-z]+@pwc\.com$/;
    const RS = /^[-+.0-9A-Z_a-z]+@scyne\.com\.au$/;
    const result = (RE).test(emailId);
    const resultNew = (RU).test(emailId);
    const resultScyne = (RS).test(emailId);
    return result || resultNew || resultScyne;
  }

  redirectToIAM(email) {
    const isInternalUser = this.isInternalPwcUser(email);
    const query = QueryString.stringify({
      response_type: 'code',
      // eslint-disable-next-line no-undef
      client_id: __CONFIG__.appIamClientId,
      redirect_uri: this.props.redirectUrl,
      scope: 'ventures openid',
      org_id: isInternalUser ? __CONFIG__.fedorgid : __CONFIG__.orgid,
      email,
    }, { strict: false, sort: false });

    // eslint-disable-next-line no-undef
    const iamUrl = `${__CONFIG__.appIamEndpoint}?${query}`;
    this.redirectToUrl(iamUrl);
  }

  validateEmail(email) {
    this.showErrorMessage('', false);
    if (!email) {
      this.showErrorMessage('Email is required.');
      return false;
    }

    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return true;
    }

    this.showErrorMessage('Email Address is not valid');
    return false;
  }

  validateRedirectUserEmail(email) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return true;
    }
    return false;
  }

  redirectToUrl(url, isUrlSaved) {
    const savedUrl = authService.getRedirectUrl();
    if (isUrlSaved) {
      authService.removeRedirectUrl();
    }
    window.location = isUrlSaved ? savedUrl || url : url;
  }

  redirectToMobileUrl(url) {
    window.location = url;
  }

  render() {
    const {
      isLoading, hasError, errorMessage, isSignIn, isLogin,
      isLoginFromRedirectUrl,
    } = this.state;
    const { brandName } = this.props;

    return (
      <React.Fragment>
        {this.state.showErrorAlert
          && <ErrorAlertModel onCloseAlertHandler={this.onCloseErrorAlertHandler}
            modelText={constant.USER_STAKEHOLDER_ERROR} />}
        <div>
          <div className="login-page row g-0 p-0 m-0">
            <div className="left-side">
              <div className="bg-block text-center center-block">
                <div className="logo-box-wrapper center-block">
                  <div className="logo-box" />
                </div>
                <div className="notice-text">
                  <h2>{brandName}</h2>
                  <h3>Capture sentiment and uncover insights</h3>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="content center-block justify-content-start align-items-center">
                {isLogin && (<div className="login-box new">

                  <Formik
                    initialValues={this.state}
                    onSubmit={(value) => {
                      this.validateUser(value);
                    }}
                  >
                    {({ setFieldValue }) => (
                      <React.Fragment>
                        <RenderIf showComponent={!isSignIn}>
                          <Form>
                            <div className="logo-box-wrapper center-block d-block d-sm-none">
                              <div className="logo-box" />
                            </div>
                            <div className="notice-text text-left">
                              <BrandWrapper>
                                <h2>Sign in</h2>
                              </BrandWrapper>
                            </div>
                            <div className="form-group">
                              <div className="a-form-label">email address </div>
                              {!isLoginFromRedirectUrl && <Field
                                onChange={(e) => {
                                  setFieldValue('email', e.target.value);
                                }}
                                disabled={isSignIn}
                                autoComplete="off"
                                name="email"
                                placeholder="Email Address..."
                                className={
                                  hasError
                                    ? 'error a-text-input t-invalid-input'
                                    : 'a-text-input'
                                }
                              />}
                              {isLoginFromRedirectUrl && <Field
                                disabled={isSignIn}
                                autoComplete="off"
                                name="email"
                                placeholder="Email Address..."
                                className={
                                  hasError
                                    ? 'error a-text-input t-invalid-input'
                                    : 'a-text-input'
                                }
                                value={this.state.email}
                              />}

                              <RenderIf showComponent={hasError}>
                                <div className="error">{errorMessage}</div>
                              </RenderIf>
                            </div>

                            <div className="form-group login-control"><SpinnerButton isLoading={isLoading} label="Continue" /></div>

                            <hr className="reg-seprator"></hr>

                            <label className="terms-text checkbox a-font-sm font-weight-medium ">
                              By clicking 'Continue', you agree to our{' '}
                              <a href="/terms-of-use" target="_blank">
                                Terms of Use
                              </a>{' '}
                              and our{' '}
                              <a href="/information-collection-statement" target="_blank">
                                Information Collection Statement
                              </a>
                              .
                            </label>

                          </Form>
                        </RenderIf>
                        <RenderIf showComponent={isSignIn}>
                          <div className="form-group login-loader-container">
                            <Spinner showLoadingMessage={true} loadingMessage={'Signing in...'} isLoading={isSignIn} />
                          </div>
                        </RenderIf>
                      </React.Fragment>
                    )}
                  </Formik>

                  <div className="at-footer">
                    <p>
                      © {constant.currentYear} PwC. All rights reserved.
                      PwC refers to the Australia member firm of
                      the PwC network or one of its subsidiaries or affiliates.
                    </p>
                  </div>
                </div>)}

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
  redirectUrl: state.brandwrapper.redirectUrl,
  CSRFToken: state.brandwrapper.csrfToken,
  userEmail: state.login.userEmail,
  guid: state.questionnaire.guid,
});

const mapDispatchToProps = {
  login,
  isLoggedInUserAdmin,
  registerUser,
  isUserAlreadyRegistered,
  setCsrfToken,
  fetchCsrfToken,
  validateOnDemandUserByEmail,
  setUserEmail,
  checkIfUserRestrictedToLogin,
  checkDeepLinkUser,
  isFirstTimeLogin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Login));
