import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import { ConnectedRouter } from 'connected-react-router';
import $ from 'jquery';
import 'core-js/stable';

import AppRoutes from './routes.jsx';
import configureStore, { history } from './redux/configureStore';
import 'bootstrap/dist/css/bootstrap.css';
import 'appkit/sass/appkit.scss';
import { Loader } from './ui-components/Loader';
/* eslint-disable */
import axiosInterceptor from './redux/axios/axiosInterceptor';
/* eslint-enable */
import * as serviceWorker from '../serviceWorker';
import CSRFTokenWrapper from './modules/csrftokenwrapper/components/csrftokenwrapper';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

const store = configureStore();
export default store;

let initialState = {};

// rehydrate initialState for JS app
/* eslint-disable no-underscore-dangle */
if (window.__INITIAL_STATE__) {
  initialState = window.__INITIAL_STATE__;
  /* eslint-enable no-underscore-dangle */

  // Transform into Immutable.js collections,
  // but leave top level keys untouched for Redux
  Object.keys(initialState).forEach((key) => {
    initialState[key] = fromJS(initialState[key]);
  });
}

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools

// the history object provided to router reducer, routerMiddleware, and
// ConnectedRouter component must be the same history object.
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.Fragment>
        <CSRFTokenWrapper />
        <AppRoutes history={history} />
        <Loader />
      </React.Fragment>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app-container'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
