import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';
import $ from 'jquery';
import constant from '../../account/actions/constant';
import authService from '../../../redux/services/authService';
import { isLoggedInUserAdmin } from '../../account/actions/login';
import {
  resendAccountActivationEmail,
} from '../../account/actions/iam';
import ResentMailSuccessMessage from '../../../ui-components/ResentMailSuccessMessage';

class AccountActivation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authResponse: {},
      hasAccess: false,
      email: '',
      isResentActivationEmail: false,
    };
    this.redirectToHomePage = this.redirectToHomePage.bind(this);
    this.backToSignInPage = this.backToSignInPage.bind(this);
  }

  componentDidMount() {
    this.setState({ email: this.props.userEmail });
    if (this.props.location.state !== undefined) {
      const { authResponse } = this.props.location.state;

      this.setState({ authResponse, hasAccess: authResponse.data.stakeholderHasAccessToDashboard });
    }
    this.initializeSuccessAlert();

    if (!this.props.userEmail) {
      this.backToSignInPage();
    }
  }

  initializeSuccessAlert() {
    $('.success-alert').alert('show');
  }


  redirectToHomePage() {
    const { homePage } = constant;
    authService.removeSignoutUserEmail();
    this.props.isLoggedInUserAdmin(this.state.authResponse.data.userInfo.isSysAdmin);
    this.props.push(homePage);
  }

  backToSignInPage() {
    const { signInPage } = constant;
    this.navigate(signInPage);
  }

  navigate(path) {
    const { location } = this.props;
    if (location.pathname !== path) this.props.push(path);
  }

  resendActivationLink(userEmail) {
    if (userEmail) {
      this.setState({ isResentActivationEmail: true });
      this.props.resendAccountActivationEmail({ email: userEmail }).then(() => {
        setTimeout(() => {
          this.setState({ isResentActivationEmail: false });
        }, 300000);
      });
    }
  }

  render() {
    const {
      isResentActivationEmail,
    } = this.state;
    const {
      brandName, userEmail,
    } = this.props;
    const messageText = 'Verification email resent. Please wait for 5 mins before sending another one.';
    return (
      <React.Fragment>
        <div className="public-container">
          {isResentActivationEmail && (<ResentMailSuccessMessage alertMesage={messageText} />)}
          <div className="login-page account-activation-login row g-0 p-0 m-0">
            <div className="left-side">
              <div className="bg-block text-center center-block">
                <div className="logo-box-wrapper center-block">
                  <div className="logo-box" />
                </div>
                <div className="notice-text">
                  <h2>{brandName}</h2>
                  <h3>Capture sentiment and uncover insights</h3>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="content center-block justify-content-start align-items-center">
                <div className="activation-wrap p-5">
                  <div className="activation-inner">
                    <div className="activation-icon text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="66" height="64" viewBox="0 0 66 64">
                        <defs>
                          <path id="a" d="M0 .963h65.118v24.784H0z"/>
                          <path id="c" d="M2 68.037h65.118V0H0z"/>
                        </defs>
                        <g transform="translate(-2 -5)" fill="red" >
                          <path d="M2 29.783v34.193a4.06 4.06 0 0 0 4.061 4.06h56.997a4.06 4.06 0 0 0 4.06-4.06V29.783H2Z" fill="#AAA"/>
                          <g transform="translate(2 4.037)">
                            <mask id="b" fill="#fff">
                              <use href="#a"/>
                            </mask>
                            <g/>
                            <path d="m63.66 22.852-29-21.203a3.56 3.56 0 0 0-4.202 0l-29 21.203A3.56 3.56 0 0 0 0 25.726v.021h65.118v-.02a3.56 3.56 0 0 0-1.458-2.873" fill="#D8D7DB" mask="url(#b)"/>
                          </g>
                          <path d="M11.552 10.086c-.703 0-1.273.57-1.273 1.274v38.907c0 .703.57 1.273 1.273 1.273h45.85c.702 0 1.271-.57 1.271-1.273V11.36c0-.703-.569-1.273-1.272-1.273H11.552Z" fill="#F5AA82"/>
                          <mask id="d" fill="#fff">
                            <use href="#c"/>
                          </mask>
                          <g/>
                          <path fill="#E95A0A" mask="url(#d)" d="M17.569 19.547h33.816v-1.932H17.569zm0 11.721h33.816v-1.932H17.569zm0 5.861h33.816v-1.932H17.569zm0-11.721h33.816v-1.932H17.569z"/>
                          <path d="M6.06 68.037h57.066a3.992 3.992 0 0 0 3.993-3.992V29.784L6.06 68.037Z" fill="#FEFFFF" mask="url(#d)"/>
                          <path d="M2 29.783v34.261a3.992 3.992 0 0 0 3.992 3.992h57.066L2 29.783Z" fill="#F2F0F7" mask="url(#d)"/>
                        </g>
                      </svg>
                    </div>
                    <h4 className="my-4 text-center">Activate your new account</h4>
                    <p>
                      It looks like you're signing in to Project Perspectives for the first time.
                      To set up your account we need to confirm that this email address (<a href="javascript:void(0); ">{userEmail}</a>)
                      belongs to you.
                      Please click on the link in the email that we've sent you to continue
                      the registration process.
                    </p>

                    <p >
                      If you don't receive email, please check your Junk Mail folder or{' '}<button disabled={isResentActivationEmail} class="ac-resend-button a-btn a-btn-secondary a-btn-sm resend-btn bg-white pl-0 border-0"
                        onClick={() => this.resendActivationLink(userEmail)}>
                        click here to resend.
                      </button>
                    </p>
                    <button class="a-btn a-btn-secondary bt-login a-btn-lg bg-white pl-0 border-0" onClick={() => this.backToSignInPage()}>
                      <span class="appkiticon icon-left-chevron-fill mr-2"></span>Back to Login
                    </button>
                    <div class="at-footer"><p>© {constant.currentYear} PwC. All rights reserved. PwC refers to the Australia member firm of the PwC network or one of its subsidiaries or affiliates.</p></div>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  isLoggedInUserAdmin,
  push,
  resendAccountActivationEmail,
};

const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
  playStoreUrl: state.brandwrapper.playStoreUrl,
  appStoreUrl: state.brandwrapper.appStoreUrl,
  baseUrl: state.brandwrapper.baseUrl,
  userEmail: state.login.userEmail,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountActivation);
